<template>
	<div class="solo-project">
		<div class="container">
			<Breadcrumb :pages="pages" class="solo-project__breadcrumb" />

			<Images v-if="!isLoad" :images="images" class="solo-project__images" />
			<or-skeleton v-else class="solo-project__images--skeleton" />

			<Title
				:is-load="isLoad"
				:content="{ ...infoProject, project: { ...infoProject.region } }"
				type="project"
				class="solo-project__block"
			/>

			<Immobilien
				:content="infoProject"
				:is-load="isLoad"
				:address="address"
				type="project"
				class="solo-project__block"
			/>

			<Showcase
				v-if="!isLoad"
				:content="infoProject"
				class="solo-project__block solo-project__block--showcase"
			/>
			<or-skeleton v-else class="solo-project__block--skeleton-showcase" />

			<template v-if="!isLoad">
				<KindsHousing
					v-if="infoProject.announcement_types?.length"
					class="solo-project__block solo-project__block--tags"
					:title="$t('mobile.complex_kinds_housing')"
					type="project"
					:project="{
						title: infoProject.title,
						slug: infoProject.slug,
						region: infoProject.region
					}"
					:content="infoProject.announcement_types"
				/>
			</template>
			<or-skeleton v-else class="solo-project__block--skeleton" />

			<template v-if="!isLoad">
				<Tags
					v-if="externalInfrastructure?.length"
					:title="$t('ads.externalInf')"
					:content="externalInfrastructure"
					class="solo-project__block solo-project__block--tags"
				/>
			</template>
			<or-skeleton v-else class="solo-project__block--skeleton" />

			<template v-if="!isLoad">
				<Tags
					v-if="internalInfrastructure?.length"
					:title="$t('ads.iternalInf')"
					:content="internalInfrastructure"
					class="solo-project__block solo-project__block--tags"
				/>
			</template>
			<or-skeleton v-else class="solo-project__block--skeleton" />

			<template v-if="!isLoad">
				<Tags
					v-if="features?.length"
					:title="$t('ads.features')"
					:content="features"
					class="solo-project__block solo-project__block--tags"
				/>
			</template>
			<or-skeleton v-else class="solo-project__block--skeleton" />

			<template v-if="!isLoad">
				<Rooms
					v-if="mapPlan?.length"
					class="solo-project__block"
					:plan="mapPlan"
				/>
			</template>
			<or-skeleton v-else class="solo-project__block--skeleton-rooms" />

			<template v-if="!isLoad">
				<SpecialOffers
					v-if="paymentInfo?.length"
					:loanOffersList="paymentInfo"
					class="solo-project__block"
				/>
			</template>
			<or-skeleton v-else class="solo-project__block--skeleton-offers" />

			<Gallery
				class="solo-project__gallery"
				v-if="adsListByProject.items?.length"
				:title="$t('general.realEstate')"
				:content="adsListByProject.items"
				:options="{ type: 'EstateCard', alt: 'Estate logo' }"
				card="EstateCard"
				@cardClick="redirect"
			/>
		</div>
	</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { i18n } from '@/i18n'
import { getDateInFormat } from '@/utils/dateTime.js'
import { setCommonMetaData } from '@/utils/setMetaData'

import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'

import Breadcrumb from '@/components/Breadcrumb'
import Images from '@/components/SoloPages/Images'
import Title from '@/components/SoloPages/TitleLine'
import Immobilien from '@/components/SoloPages/Immobilien'
import Showcase from '@/components/SoloPages/Showcase'
import KindsHousing from '@/components/SoloPages/KindsHousing'
import Tags from '@/components/SoloPages/Tags'
import Rooms from '@/components/SoloPages/Rooms'
import SpecialOffers from '@/components/SoloPages/SpecialOffers'

import Gallery from '@/components/Gallery.vue'

export default {
	name: 'SoloProject',
	components: {
		Breadcrumb,
		Images,
		Title,
		Immobilien,
		Showcase,
		KindsHousing,
		Tags,
		Rooms,
		SpecialOffers,
		Gallery
	},
	setup() {
		const infoProject = ref({
			amenity: [],
			plans_without_studios: [],
			plans_with_studios: []
		})
		const isLoad = ref(true)
		const adsListByProject = ref({})

		const router = useRouter()
		const route = useRoute()
		const store = useStore()

		const mapPlan = computed(() =>
			infoProject.value?.plans_without_studios?.length > 0 ||
			infoProject.value?.plans_with_studios.length > 0
				? [
						...infoProject.value?.plans_with_studios,
						...infoProject.value?.plans_without_studios
				  ]
				: []
		)

		const pages = computed(() => ({
			first: {
				title: i18n.global.t('general.projects'),
				srcName: 'Projects'
			},
			current: infoProject.value?.title
		}))

		const features = computed(
			() =>
				infoProject.value?.amenity?.filter((el) => el.type === 'features')[0]
					?.name
		)
		const externalInfrastructure = computed(
			() =>
				infoProject.value?.amenity?.filter((el) => el.type === 'exterenal')[0]
					?.name
		)
		const internalInfrastructure = computed(
			() =>
				infoProject.value?.amenity?.filter((el) => el.type === 'internal')[0]
					?.name
		)
		const cardInfo = computed(() => [
			{
				title: i18n.global.t('ads.stagesTitle'),
				text: infoProject.value?.date_complete
					? getDateInFormat(
							infoProject.value?.date_complete,
							store.state.uiLanguage
					  )
					: null
			},
			{
				title: infoProject.value?.developer?.title,
				text: i18n.global.t('general.developer'),
				linkName: 'SoloDeveloper',
				linkSlug: infoProject.value?.developer?.slug
			}
		])

		const address = computed(() => ({
			...infoProject.value.location,
			region: { ...infoProject.value.region },
			city: { ...infoProject.value.city }
		}))

		const paymentInfo = computed(() =>
			infoProject.value?.loan_offers?.filter((el) => el.title && el.description)
		)

		const images = computed(() => {
			let images = []
			if (infoProject.value.images)
				images = images.concat(infoProject.value.images)
			if (infoProject.value.images_interior)
				images = images.concat(infoProject.value.images_interior)
			return images
		})

		const redirect = (_, payload) => {
			router.push({
				name: 'SoloAds',
				params: { slug: payload.slug, lang: store.state.uiLanguage }
			})
		}

		const _get = () => {
			createRequest(requestConfigs.GETInfoProject, {
				routerPayload: { slug: route.params.slug }
			})
				.then((result) => {
					infoProject.value = result.response

					setCommonMetaData(
						`${i18n.global.t('titlePages.project', {
							name: infoProject.value.title,
							region: infoProject.value.region.title,
							developer: infoProject.value.developer.title,
							city: infoProject.value.city.title
						})} - OWRealty`,
						{
							description: i18n.global.t('meta.projectDesc'),
							type: 'article',
							title: `${i18n.global.t('titlePages.project', {
								name: infoProject.value.title,
								region: infoProject.value.region.title,
								developer: infoProject.value.developer.title,
								city: infoProject.value.city.title
							})} - OWRealty`,
							url: window.location.href
						}
					)
				})
				.catch((err) =>
					store.commit('setError', { status: err.errCode || 404 })
				)
				.finally(() => (isLoad.value = false))

			createRequest(requestConfigs.GETSearch, {
				queryPayload: {
					pr_slug: route.params.slug
				}
			}).then((result) => (adsListByProject.value = result.response))
		}

		_get()

		return {
			isLoad,

			infoProject,
			adsListByProject,

			mapPlan,
			address,
			pages,
			paymentInfo,
			cardInfo,
			images,

			features,
			internalInfrastructure,
			externalInfrastructure,

			carouselSettings: {
				settings: {
					itemsToShow: 4,
					snapAlign: 'start',
					itemsToScroll: 4,
					wrapAround: false
				},
				breakpoints: {
					320: { itemsToShow: 1 },
					460: { itemsToShow: 2 },
					920: { itemsToShow: 3, touchDrag: true },
					1100: { itemsToShow: 4, itemsToScroll: 0, touchDrag: false }
				}
			},

			redirect
		}
	}
}
</script>

<style lang="scss">
.solo-project {
	padding-bottom: 50px;

	&__breadcrumb {
		margin-bottom: 30px;
	}
	& .container {
		overflow: unset;
	}
	&__images {
		margin-bottom: 40px;

		&--skeleton {
			height: 380px;
			width: 100%;
			margin-bottom: 40px;

			@media screen and (max-width: 768px) {
				height: 300px;
			}
		}
	}

	&__block {
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		&--skeleton {
			width: 100%;
			height: 110px;
			margin-bottom: 30px;
		}

		&--skeleton-showcase {
			width: 100%;
			height: 80px;
			margin-bottom: 30px;
		}

		&--skeleton-rooms {
			width: 100%;
			height: 490px;
			margin-bottom: 30px;
		}

		&--skeleton-offers {
			width: 100%;
			height: 210px;
		}

		&--tags {
			padding-bottom: 20px;
		}

		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	&__gallery {
		.project-card__image {
			img {
				width: 280px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		padding-bottom: 40px;

		&__block {
			&--showcase {
				padding-bottom: 0;
				border-bottom: none;
			}
		}
	}
}
</style>
